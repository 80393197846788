@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;600;800&family=Open+Sans+Condensed:wght@700&family=Open+Sans:wght@300;400;700&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;


.font-open-sans {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
}

.btn-primary {
    @apply rounded p-4 bg-primary text-secondary w-full inline-block text-center font-bold;
}

.btn-secondary {
    @apply rounded p-4 bg-secondary text-secondary w-full inline-block text-center font-bold;
}

.btn-common {
    @apply py-2 uppercase font-open-sans font-bold text-center text-xs;
}

.btn-common-font-normal {
    @apply py-2 uppercase font-open-sans font-normal text-center text-xs;
}

@screen md {
    .btn-common, .btn-common-normal-font {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        font-size: .875rem;
    }
}

.btn-green {
    background-color: #87a544;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0;
    @apply rounded py-2 pl-4 text-white text-center pr-8 relative;
}

.btn-green::after {
    content: ">";
    font-weight: bold;
    font-size: 1.5em;
    position: absolute;
    bottom: 2px;
    margin-left: 10px;
}
.btn-green:hover {
    opacity: 0.75;
}

.btn-green-secondary {
    border: 2px solid #87a544;
    color: #87a544;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: 0;
    @apply rounded py-2 pl-4 text-center pr-8 relative;
}

.btn-green-secondary::after {
    content: ">";
    font-weight: bold;
    font-size: 1.5em;
    position: absolute;
    bottom: 2px;
    margin-left: 10px;
}

.btn-green-secondary:hover {
    opacity: 0.75;
}

.btn-round {
    float: right;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    min-width: 25px;
}

.btn-report-entry {
    height: 25px;
    border-radius: 15px;
    @apply bg-primary px-2 text-white;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    top: 1rem;
    right: 50px;
}

.btn-solved-entry {
    height: 25px;
    border-radius: 15px;
    @apply bg-secondary px-2 text-white;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    top: 1rem;
    right: 50px;
}

.btn-report-unflagged {
    background-color: #f3e8ea;
}

.btn-report-flagged {
    background-color: #f9f7f0;
}

.btn-report-abort {
    background-color: #dddddd;
}

.btn-solve-unsolved {
    @apply bg-tertiary;
}

.btn-solve-abort {
    background-color: #dddddd;
}

.flag {
    height: 1.1rem;
 }

.cross {
    height: 0.8rem;
 }

 .dots {
    height: 1.6rem;
 }

.checkmark {
    height: 0.8rem;
 }

.input-focus {
    border-width: 1px;
    margin-bottom: 2px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    @apply border rounded p-3 leading-tight w-full;
}

.input-focus:focus {
    border-bottom: 3px solid #87a544;
    margin-bottom: 0;
    outline: none;
}

.arrow-more-content {
    position: absolute;
    bottom: 10px;
    left: calc(50% - 20px);

    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 0.95;

    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;

    cursor: pointer;
}
.scroll-up-btn {
    z-index: 500;

    position: fixed;
    right: -100px;
    bottom: 20px;
    transition: right 0.5s;
    cursor: pointer;

    border-radius: 50%;
    width: 40px;
    height: 40px;
    opacity: 0.95;

    background-color: rgba(119, 119, 119, 0.9);
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);

    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-up-btn-fade {
    right: 20px;
}

.btn-light {
    background-color: #eaefde;
    color: #87a544;
}

.slider-track {
    height: 10px;
    border-radius: 5px;
}

.slider-track-0 {
    background-color: #87a544;
}

.slider-track-1 {
    background-color: #eaefde;
}

.slider-handle {
    width: 25px;
    height: 25px;
    line-height: 8px;
    background-color: #87a544;
    border-radius: 50%;
    border: solid 4px #ffffff;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.7);
}

.bg-gray-custom {
    background-color: #f2f2f2;
}

.loader {
    border: 6px solid #eaefde;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader-loading {
    transition: rotate 1s;
    border: 6px solid #eaefde !important;
    border-top: 6px solid #87a544 !important;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.loader-1 {
    border-top: 6px solid #87a544;
}

.loader-2 {
    border-top: 6px solid #87a544;
    border-right: 6px solid #87a544;
}

.loader-3 {
    border-top: 6px solid #87a544;
    border-right: 6px solid #87a544;
    border-bottom: 6px solid #87a544;
}

.loader-4 {
    border-top: 6px solid #87a544;
    border-right: 6px solid #87a544;
    border-bottom: 6px solid #87a544;
    border-left: 6px solid #87a544;
}

.text-fine-print {
    font-size: 4pt;
    color: #aaaaaa;
}

.iframe-header {
    height: 190px;
    background-image: linear-gradient(5deg, #f9f7f0 -37%, #b6223e 117%);
}

.iframe-header-logo {
    width: calc(100% + 250px);
    max-width: 325px;
    max-height: 200px;
}

.iframe-header-buttons {
    width: 600px;
    min-width: 400px;
}

.iframe-header-overlay {
    width: calc(45% - 250px);
    background-color: #b6223e;
}

.iframe-header-overlay::after {
    content: "";
    position: absolute;
    top: -250px;
    left: calc(100% - 250px);
    border-radius: 50%;
    width: 500px;
    height: 500px;
    background-color: #b6223e;
    z-index: -1;
}

.iframe-small-header {
    background-image: linear-gradient(5deg, #f9f7f0 -37%, #b6223e 117%);
}

.iframe-small-overlay {
    position: absolute;
    top: -150px;
    left: -125vw;
    border-radius: 50%;
    width: 250vw;
    height: 400px;
    background-color: #b6223e;
}

.iframe-small-logo {
    max-width: 300px;
    width: 90%;
}

.btn-white-shadow {
    box-shadow: 0 0 20px -4px rgba(255, 255, 255, 0.5);
}

.btn-white-shadow:hover {
    box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.8);
}

.address-clickable:hover {
    color: #87a544
}

.hyphens-auto {
    hyphens: auto;
}

input:checked + svg, input:checked + img {
    display: block;
}

.checkbox:focus-within {
    border: 2px solid #87a544;
}

.overflow-fade {
    margin-left: -3rem;
    margin-right: -3rem;
}

.overflow-fade > table {
    margin-left: 3rem;
    margin-right: 3rem;
}

.overflow-fade:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: -3rem;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 40%);
    width: 4rem;
}

.overflow-fade:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: -3rem;
    pointer-events: none;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white 40%);
    width: 4rem;
}

.popup-context-menu-content {
    border: none;
}

.popup-context-menu-arrow {
    display: none;
}
