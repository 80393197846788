.cluster-marker {
  color: #fff;
  background: #b6223e;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
}

.cm-selected {
  background-color: #dd4864;
  transform: scale(1.25);
  box-shadow: 3px 3px 10px 0px rgba(0,0,0);
  position: absolute;
  transform: translate(-50%, -50%);
}

.help-request-marker {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #b6223e;
  box-shadow: -12px 12px 10px -7px rgba(0,0,0,0.75);
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.help-request-image {
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  margin: 5px;
}

.hrm-selected {
  background-color: #dd4864;
  transform: rotate(-45deg) scale(1.25);
  box-shadow: -12px 12px 10px -7px rgba(0,0,0);
}

.hrm-selected>img {
  margin: 5px;
}